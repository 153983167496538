// src/storage/utils/validation.js
import { STORAGE_CONFIG } from '../config/constants';

export const validateFile = (file) => {
  if (!STORAGE_CONFIG.ALLOWED_TYPES[file.type]) {
    throw new Error(`Tipo de archivo no permitido: ${file.type}`);
  }

  if (file.size > STORAGE_CONFIG.MAX_FILE_SIZE) {
    throw new Error(`El archivo excede el tamaño máximo de ${STORAGE_CONFIG.MAX_FILE_SIZE / (1024 * 1024)}MB`);
  }

  return true;
};

export const validateFiles = (files) => {
  const MAX_FILES = 5;
  const errors = [];

  if (files.length > MAX_FILES) {
    errors.push(`No se pueden subir más de ${MAX_FILES} archivos`);
  }

  files.forEach(file => {
    try {
      validateFile(file);
    } catch (error) {
      errors.push(`${file.name}: ${error.message}`);
    }
  });

  if (errors.length > 0) {
    throw new Error(errors.join('\n'));
  }

  return true;
};