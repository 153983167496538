// src/features/auth/hooks/useAuthAnalytics.js

import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useAuthAnalytics = () => {
  const { track, identify } = useAnalytics();

  const trackLogin = useCallback((userId, email, context = 'normal') => {
    identify(userId, { email, userType: 'admin' });
    track(EVENT_TYPES.LOGIN, { userId, email, context });
  }, [track, identify]);

  const trackLogout = useCallback((userId) => {
    track(EVENT_TYPES.LOGOUT, { userId });
  }, [track]);

  const trackPasswordReset = useCallback((email) => {
    track(EVENT_TYPES.PASSWORD_RESET_REQUESTED, { email });
  }, [track]);

  const trackPasswordResetCompleted = useCallback((userId) => {
    track(EVENT_TYPES.PASSWORD_RESET_COMPLETED, { userId });
  }, [track]);

  return {
    trackLogin,
    trackLogout,
    trackPasswordReset,
    trackPasswordResetCompleted,
  };
};