// src/components/EmptyStateAlert.js

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { AlertTriangle } from 'lucide-react';

const EmptyStateAlert = ({ message, buttonLabel, onButtonClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FFFBEB',
        border: '1px solid #FEF3C7',
        borderRadius: '16px',
        padding: '16px 24px',
        gap: 2,
        marginBottom: 4, // Added margin for spacing
      }}
    >
      {/* Left Section: Icon and Message */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
        <AlertTriangle size={24} color="#B45309" />
        <Typography
          sx={{
            fontSize: '14px',
            color: '#92400E',
            lineHeight: '1.5',
            fontWeight: 400,
          }}
        >
          {message}
        </Typography>
      </Box>

      {/* Right Section: Button */}
      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{
          backgroundColor: '#1F2937',
          color: 'white',
          fontSize: '14px',
          fontWeight: 500,
          textTransform: 'none',
          borderRadius: '24px',
          padding: '10px 20px',
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: '#111827',
          },
        }}
      >
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default EmptyStateAlert;