// src/features/maintenanceFee/components/maintenanceFeeDetail/MaintenanceFeeDetailStats.js

import React from 'react';
import { Box, Typography, Chip } from '@mui/material';

const MaintenanceFeeDetailStats = ({ 
  apartmentNumber, 
  residentName, 
  emiCuota = 0,
  montoPagado = 0,
  porValidar = 0,
  deuda = 0
}) => {
  // Determine status chip based on debt
  const statusLabel = deuda === 0 ? 'Al día' : 'Pendiente';
  const statusColor = deuda === 0 ? '#DCFCE7' : '#FEE2E2';
  const statusTextColor = deuda === 0 ? '#166534' : '#991B1B';

  return (
    <Box sx={{ /* styles */ }}>
      {/* Apartment Info Section */}
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: '24px', color: '#111827' }}>
          Departamento - {apartmentNumber}
        </Typography>
        <Typography sx={{ fontSize: '16px', color: '#6B7280' }}>
          {residentName || 'Sin residente'}
        </Typography>
      </Box>

      {/* Stats Section */}
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: 2, alignItems: 'center' }}>
        {/* Emisión Cuota M. */}
        <Box>
          <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
            Emisión Cuota M.
          </Typography>
          <Typography sx={{ color: emiCuota === 0 ? '#6B7280' : '#111827', fontSize: 14, fontWeight: 600 }}>
            S/ {emiCuota.toFixed(2)}
          </Typography>
        </Box>

        {/* Monto Pagado */}
        <Box>
          <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
            Monto Pagado
          </Typography>
          <Typography sx={{ color: montoPagado === 0 ? '#6B7280' : '#111827', fontSize: 14, fontWeight: 600 }}>
            S/ {montoPagado.toFixed(2)}
          </Typography>
        </Box>

        {/* Por Validar */}
        <Box>
          <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
            Por Validar
          </Typography>
          <Typography sx={{ color: porValidar > 0 ? '#F59E0B' : '#6B7280', fontSize: 14, fontWeight: 600 }}>
            S/ {porValidar.toFixed(2)}
          </Typography>
        </Box>

        {/* Deuda */}
        <Box>
          <Typography sx={{ color: '#6B7280', fontSize: 14 }}>
            Deuda
          </Typography>
          <Typography sx={{ color: deuda > 0 ? '#EF4444' : '#6B7280', fontSize: 14, fontWeight: 600 }}>
            S/ {deuda.toFixed(2)}
          </Typography>
        </Box>

        {/* Status Chip */}
        <Box>
          <Chip
            label={statusLabel}
            sx={{
              backgroundColor: statusColor,
              color: statusTextColor,
              fontWeight: 600,
              fontSize: '14px',
              height: '28px',
              padding: '0 12px',
              borderRadius: '16px',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MaintenanceFeeDetailStats;