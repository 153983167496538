// src/analytics/index.ts

import { EVENT_TYPES } from './config/events';
import { FEATURE_FLAGS } from './config/featureFlags';
import { GROUP_TYPES } from './config/groups';
import * as posthog from './services/posthog';

// Definir los tipos para los servicios
type AnalyticsService = {
  init: () => void;
  trackEvent: (eventName: string, properties?: Record<string, any>) => void;
  identifyUser: (userId: string, traits?: Record<string, any>) => void;
  resetUser: () => void;
  isFeatureEnabled: (flagKey: string, defaultValue?: boolean) => boolean | undefined;
  getFeatureFlagValue: (flagKey: string) => any;
  setAnalyticsGroup: (groupType: string, groupKey: string, groupProperties?: Record<string, any>) => void;
};

// This array can be expanded to include other services in the future
const services: AnalyticsService[] = [posthog];

export const initAnalytics = (): void => {
  services.forEach(service => service.init());
};

export const trackPageView = (path: string) => {
  services.forEach(service => service.trackEvent('$pageview', { path }));
};

export const trackEvent = (eventName: string, properties:  Record<string, any> = {}) => {
  services.forEach(service => service.trackEvent(eventName, properties));
};

export const identifyUser = (userId: string, traits: Record<string, any> = {}) => {
  services.forEach(service => service.identifyUser(userId, traits));
};

export const resetUser = () => {
  services.forEach(service => service.resetUser());
};

export const isFeatureEnabled = (flagKey: string, defaultValue: boolean = false) => {
  // We'll keep using PostHog for feature flags for now
  return posthog.isFeatureEnabled(flagKey, defaultValue);
};

export const getFeatureFlagValue = (flagKey: string): any => {
  // We'll keep using PostHog for feature flags for now
  return posthog.getFeatureFlagValue(flagKey);
};

export const setAnalyticsGroup = (groupType: string, groupKey: string, groupProperties: Record<string, any> = {}) => {
  services.forEach(service => service.setAnalyticsGroup(groupType, groupKey, groupProperties));
};

export { EVENT_TYPES, FEATURE_FLAGS, GROUP_TYPES };