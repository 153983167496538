// src/features/incident/components/detail/IncidentTimeline.js
import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab';
import { Typography, Box } from '@mui/material'; // Remove unused imports
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { STATUS_CONFIG } from '../../constants';
import { 
  AlertCircle, 
  Clock, 
  CheckCircle2, 
  UserCheck,
  Settings,
  Archive 
} from 'lucide-react';

const TIMELINE_STATUS_CONFIG = {
  new: { 
    ...STATUS_CONFIG.new,
    icon: AlertCircle,
    description: 'El incidente ha sido registrado en el sistema'
  },
  in_progress: { 
    ...STATUS_CONFIG.in_progress,
    icon: Settings,
    description: 'El equipo de mantenimiento está trabajando en la solución'
  },
  pending_review: { 
    ...STATUS_CONFIG.pending_review,
    icon: Clock,
    description: 'Se requiere la revisión del residente para confirmar la solución'
  },
  resolved: { 
    ...STATUS_CONFIG.resolved,
    icon: CheckCircle2,
    description: 'El incidente ha sido resuelto satisfactoriamente'
  },
  assigned: { 
    ...STATUS_CONFIG.assigned,
    icon: UserCheck,
    description: 'Un encargado ha sido asignado para atender el incidente'
  },
  closed: { 
    ...STATUS_CONFIG.closed,
    icon: Archive,
    description: 'El caso ha sido cerrado definitivamente'
  }
};

// Keep ALLOWED_TRANSITIONS commented out for now since we're not using it
// const ALLOWED_TRANSITIONS = { ... };

const IncidentTimeline = ({
  statusHistory = [],
  currentStatus,
  // onUpdateStatus, // Remove unused prop
  userRole = 'resident'
}) => {
  // Remove unused allowedNextStatuses calculation
  
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Seguimiento
      </Typography>

      <Timeline position="right" sx={{ ml: -8 }}>
        {statusHistory.map((item, index) => {
          const config = TIMELINE_STATUS_CONFIG[item.new_status];
          const Icon = config.icon;
          
          // Safely access user information with fallbacks
          const userName = item.changed_by_user 
            ? `${item.changed_by_user.first_name || ''} ${item.changed_by_user.last_name || ''}`.trim() 
            : 'Usuario';

          return (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{
                  flex: 0.2,
                  minWidth: '100px',
                  pt: 0.5
                }}
              >
                <Typography variant="caption">
                  {format(new Date(item.changed_at), "d MMM, HH:mm", { locale: es })}
                </Typography>
              </TimelineOppositeContent>
              
              <TimelineSeparator>
                <TimelineDot 
                  color={config.color}
                  sx={{ my: 0.5 }}
                >
                  <Icon size={16} />
                </TimelineDot>
                {index < statusHistory.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              
              <TimelineContent
                sx={{
                  pt: 0,
                  pb: 2,
                  pl: 2
                }}
              >
                <Typography variant="body2" fontWeight="medium">
                  {config.label}
                </Typography>
                <Typography variant="caption" color="text.secondary" display="block">
                  {config.description}
                </Typography>
                {item.notes && (
                  <Typography 
                    variant="caption" 
                    color="text.secondary" 
                    sx={{ 
                      display: 'block',
                      mt: 0.5,
                      fontStyle: 'italic'
                    }}
                  >
                    "{item.notes}"
                  </Typography>
                )}
                <Typography 
                  variant="caption" 
                  color="text.secondary" 
                  sx={{ 
                    display: 'block',
                    mt: 0.5 
                  }}
                >
                  por {userName}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Box>
  );
};

export default IncidentTimeline;