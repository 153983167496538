// src/shared/components/NotFound.js

import React, { useEffect } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useAnalytics } from '../../analytics/hooks/useAnalytics';

const NotFound = () => {
  const { trackError } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    trackError('404_PAGE_NOT_FOUND', location.pathname);
  }, [trackError, location]);

  return (
    <Box sx={{ mt: 4, textAlign: 'center' }}>
      <Typography variant="h4">404: Página no encontrada</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        Lo sentimos, la página que estás buscando no existe.
      </Typography>
      <Button component={Link} to="/" variant="contained" sx={{ mt: 2 }}>
        Volver al inicio
      </Button>
    </Box>
  );
};

export default NotFound;