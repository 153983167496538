// src/storage/config/constants.js
export const STORAGE_CONFIG = {
  BUCKET_NAME: 'storage',
  MAX_FILE_SIZE: 10 * 1024 * 1024, // 10MB
  ALLOWED_TYPES: {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'video/mp4': 'mp4',
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
  },
  FOLDERS: {
    INCIDENTS: {
      ROOT: 'incidents',
      MAIN: 'main',
      COMMENTS: 'comments',
      RESOLUTION: 'resolution'
    },
    DOCUMENTS: 'documents',
    PROFILES: 'profiles'
  }
};
