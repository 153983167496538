// src/analytics/config/events.ts

export enum EVENT_TYPES {
  // Authentication Events
  LOGIN = 'admin_login',
  LOGOUT = 'admin_logout',
  PASSWORD_RESET_REQUESTED = 'admin_password_reset_requested',
  PASSWORD_RESET_COMPLETED = 'admin_password_reset_completed',

  // Visit Management
  VISIT_CHECKED_IN = 'admin_visit_checked_in',
  VISIT_DETAILS_VIEWED = 'admin_visit_details_viewed',
  VISIT_LIST_FILTERED = 'admin_visit_list_filtered',
  VISIT_LIST_SORTED = 'admin_visit_list_sorted',
  VISIT_LIST_SEARCHED = 'admin_visit_list_searched',
  VISIT_TAB_CHANGED = 'admin_visit_tab_changed',

  // Manual Registration
  MANUAL_REGISTRATION_STARTED = 'admin_manual_registration_started',
  VISITOR_SEARCHED = 'admin_visitor_searched',
  NEW_VISITOR_CREATED = 'admin_new_visitor_created',
  MANUAL_VISIT_CREATED = 'admin_manual_visit_created',
  MANUAL_REGISTRATION_COMPLETED = 'admin_manual_registration_completed',
  MANUAL_REGISTRATION_FAILED = 'admin_manual_registration_failed',

  // Dashboard Activity
  DASHBOARD_VIEWED = 'admin_dashboard_viewed',
  PREMIUM_FEATURE_CLICKED = 'admin_premium_feature_clicked',
  PREMIUM_DIALOG_OPENED = 'admin_premium_dialog_opened',
  PREMIUM_CONTACT_CLICKED = 'admin_premium_contact_clicked',

  // System Events
  ERROR_OCCURRED = 'admin_error_occurred',
  PAGE_VIEW = 'admin_page_view',

  // Building Management
  BUILDING_ACCESSED = 'admin_building_accessed',
  APARTMENT_SELECTED = 'admin_apartment_selected',

  // Profile Actions
  PROFILE_VIEWED = 'admin_profile_viewed',
  PROFILE_UPDATED = 'admin_profile_updated',

  // Feature Usage
  FEATURE_FLAG_CHECKED = 'admin_feature_flag_checked',
  SIDEBAR_TOGGLED = 'admin_sidebar_toggled',
  NAVIGATION_PERFORMED = 'admin_navigation_performed',

  // Add Incident Events
  INCIDENT_CREATED = 'incident_created',
  INCIDENT_UPDATED = 'incident_updated',
  INCIDENT_STATUS_CHANGED = 'incident_status_changed',
  INCIDENT_COMMENT_ADDED = 'incident_comment_added',
  INCIDENT_ASSIGNED = 'incident_assigned',
  INCIDENT_VIEWED = 'incident_viewed',
  INCIDENT_MEDIA_UPLOADED = 'incident_media_uploaded',
  
    // Add more event types as needed
    DASHBOARD_WIDGET_INTERACTION = 'dashboard_widget_interaction',
    INCIDENT_RATED = 'incident_rated',
    VISIT_DETAIL_VIEWED = 'visit_detail_viewed',
    REGISTER_EMAIL_SUBMITTED = 'register_email_submitted',
    REGISTER_ERROR = 'register_error',
    REGISTER_EMAIL_CONFIRMED = 'register_email_confirmed',
    REGISTER_COMPLETED = 'register_completed',
};