import React from 'react';
import { Box, TextField, MenuItem, Select, InputLabel, FormControl, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const MaintenanceFeeTable = ({ onSearchChange, filterValue, onFilterChange }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 3,
        gap: 2,
      }}
    >
      {/* Search and Filter */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <TextField
          placeholder="Buscar..."
          onChange={(e) => onSearchChange(e.target.value)}
          size="small"
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: '#9CA3AF', marginRight: 1 }} />,
          }}
          sx={{
            width: '200px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '24px',
              fontSize: '14px',
              backgroundColor: '#F9FAFB',
            },
          }}
        />
        <FormControl size="small" sx={{ minWidth: 140 }}>
          <InputLabel sx={{ fontSize: '14px', color: '#6B7280' }}>Filtro</InputLabel>
          <Select
            value={filterValue}
            onChange={(e) => onFilterChange(e.target.value)}
            sx={{ borderRadius: '24px', fontSize: '14px', backgroundColor: '#FFFFFF' }}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value="Validados">Validados</MenuItem>
            <MenuItem value="Pendientes">Pendientes</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Buttons */}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          sx={{
            color: '#4F46E5',
            borderColor: '#E0E7FF',
            backgroundColor: '#F3F4F6',
            borderRadius: '24px',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: '#E0E7FF',
              borderColor: '#4F46E5',
            },
          }}
        >
          Exportar
        </Button>
        <Button
          variant="contained"
          sx={{
            background: 'linear-gradient(90deg, #F97316 0%, #9333EA 100%)',
            color: '#FFFFFF',
            borderRadius: '24px',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            padding: '8px 16px',
            '&:hover': { opacity: 0.9 },
          }}
        >
          Conciliación Automática
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#315CAC',
            color: '#FFFFFF',
            borderRadius: '24px',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            padding: '8px 16px',
            '&:hover': { backgroundColor: '#2A4F94' },
          }}
        >
          Añadir Pago
        </Button>
      </Box>
    </Box>
  );
};

export default MaintenanceFeeTable;