// src/App.js

import { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';

import { NavigationProvider } from './navigation/context/NavigationContext';

import ForgotPassword from './features/auth/pages/ForgotPassword';
import Login from './features/auth/pages/Login';
import Dashboard from './features/dashboard/pages/Dashboard';
import VisitMain from './features/invitation/pages/VisitMain';
import VisitDetail from './features/invitation/pages/VisitDetail';
import ManualVisitRegistration from './features/manualVisitRegistration/pages/ManualVisitRegistration';
import NotFound from './shared/components/NotFound';
import { PrivateRoute, AuthRoute } from './navigation/components/PrivateRoute';
import AuthLayout from './shared/layouts/AuthLayout';
import MainLayout from './shared/layouts/MainLayout';

import CreateIncident from './features/incidents/pages/CreateIncident';
import IncidentDetail from './features/incidents/pages/IncidentDetail';
import IncidentMain from './features/incidents/pages/IncidentMain';

import MaintenanceFee from './features/maintenanceFee/pages/MaintenanceFee';
import MaintenanceFeeDetail from './features/maintenanceFee/pages/MaintenanceFeeDetail';

import MeterMain from "./features/meters/pages/MeterMain";

import { initAnalytics } from './analytics';
import { ROUTES } from './config/routes';
import useGlobalStore from './state/store';
import AnalyticsWrapper from './analytics/components/AnalyticsWrapper';

function App() {
  const initializeStore = useGlobalStore((state) => state.initializeUser);

  useEffect(() => {
    initAnalytics();
    initializeStore();
  }, [initializeStore]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <NavigationProvider>
          <AnalyticsWrapper>
            <Routes>
              <Route element={<AuthRoute><AuthLayout /></AuthRoute>}>
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
              </Route>

              <Route element={<PrivateRoute />}>
                <Route element={<MainLayout />}>
                  <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
                  <Route path={ROUTES.VISITS} element={<VisitMain />} />
                  <Route path={ROUTES.VISIT_DETAIL} element={<VisitDetail />} />
                  <Route path={ROUTES.MANUAL_VISIT_REGISTRATION} element={<ManualVisitRegistration />} />
                  <Route path={ROUTES.INCIDENTS} element={<IncidentMain />} />
                  <Route path={ROUTES.INCIDENT_NEW} element={<CreateIncident />} />
                  <Route path={ROUTES.INCIDENT_DETAIL} element={<IncidentDetail />} />
                  <Route path={ROUTES.METERS} element={<MeterMain />} />
                  <Route path={ROUTES.MAINTENANCE_FEE} element={<MaintenanceFee />} />
                  <Route path={ROUTES.MAINTENANCE_FEE_DETAIL} element={<MaintenanceFeeDetail />} />
                </Route>
              </Route>

              <Route path="/" element={<Navigate replace to={ROUTES.LOGIN} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AnalyticsWrapper>
        </NavigationProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;