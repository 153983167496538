// src/navigation/hooks/useNavigation.js

import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../..//config/routes";
import useGlobalStore from "../../state/store";

export const useNavigation = () => {
  const location = useLocation();
  
  const invitationToken = useGlobalStore((state) => state.invitationToken);
  const setInvitationToken = useGlobalStore((state) => state.setInvitationToken);
  const buildingId = useGlobalStore((state) => state.buildingId);
  const setBuildingId = useGlobalStore((state) => state.setBuildingId);

  const getParams = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      invitationToken: searchParams.get("token") || invitationToken,
      buildingId: searchParams.get("buildingId") || buildingId,
    };
  }, [location.search, invitationToken, buildingId]);

  const navigateWithParams = useCallback(
    (route, additionalParams = {}) => {
      const params = getParams();
      const searchParams = new URLSearchParams();

      if (params.invitationToken)
        searchParams.set("token", params.invitationToken);
      if (params.buildingId) searchParams.set("buildingId", params.buildingId);

      Object.entries(additionalParams).forEach(([key, value]) => {
        if (value !== null && value !== undefined) searchParams.set(key, value);
      });

      const queryString = searchParams.toString();
      const fullPath = queryString ? `${route}?${queryString}` : route;

      // Force a page reload
      window.location.href = fullPath;
    },
    [getParams]
  );

  const clearParams = useCallback(() => {
    setInvitationToken(null);
    setBuildingId(null);
  }, [setInvitationToken, setBuildingId]);

  const clearUrlParams = useCallback(() => {
    window.history.replaceState({}, "", window.location.pathname);
  }, []);

  const getRedirectPath = useCallback(() => {
    const { invitationToken, buildingId } = getParams();
    if (invitationToken || buildingId) {
      return ROUTES.DASHBOARD;
    }
    return ROUTES.DASHBOARD;
  }, [getParams]);

  return {
    getParams,
    navigateWithParams,
    clearParams,
    clearUrlParams,
    getRedirectPath,
  };
};
