// src/features/auth/pages/ForgotPassword.js

import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { authService } from '../services/authService';
import { useAuthAnalytics } from '../hooks/useAuthAnalytics';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { trackPasswordReset } = useAuthAnalytics();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.resetPassword(email);
      trackPasswordReset(email);
      setSnackbarMessage('Se ha enviado un correo para restablecer tu contraseña');
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(error.message || "Ocurrió un error al enviar el correo de restablecimiento");
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={{ mt: 1, maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Restablecer contraseña
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          id="email"
          label="Correo electrónico"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Enviar correo de restablecimiento
        </Button>
      </form>
      <Typography align="center">
        <Link to="/login" style={{ textDecoration: 'none' }}>
          Volver a iniciar sesión
        </Link>
      </Typography>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default ForgotPassword;