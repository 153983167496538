import React from "react";
import {
  Container,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { ArrowBack, PlayArrow, CheckCircle } from "@mui/icons-material"; // Add CheckCircle here
import { useParams } from "react-router-dom";
import { useIncident } from "../hooks/useIncident";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import IncidentDetailView from "../components/detail/IncidentDetailView.jsx";
import useGlobalStore from "../../../state/store";

const IncidentDetail = () => {
  const { id } = useParams();
  const { navigateWithParams, getParams } = useNavigation();

  const user = useGlobalStore((state) => state.user);
  const userLoading = useGlobalStore((state) => state.loading);

  // Only initialize useIncident after user is loaded
  const {
    incident,
    loading: incidentLoading,
    error,
    updateIncidentStatus,
    addComment,
    assignIncidentToWorker,
  } = useIncident(user ? id : null); // Only pass id if user exists

  const handleBack = () => {
    const params = getParams();
    navigateWithParams(ROUTES.INCIDENTS, params);
  };

  const handleUpdateStatus = async (incidentId, newStatus) => {
    try {
      await updateIncidentStatus(incidentId, newStatus);
    } catch (err) {
      console.error("Error updating status:", err);
    }
  };

  const handleComment = async (commentText, mediaFiles) => {
    try {
      console.log("Submitting comment:", {
        commentText,
        mediaFiles,
        buildingId: incident.building_id,
      });

      if (!incident.building_id) {
        throw new Error("Missing building ID");
      }

      await addComment(commentText, mediaFiles);
    } catch (err) {
      console.error("Error adding comment:", err);
    }
  };

  const handleAssignToWorker = async () => {
    try {
      if (!incident.building_id) {
        throw new Error("Missing building ID");
      }

      await assignIncidentToWorker(incident.id, user.id, user.id, incident.building_id);
    } catch (err) {
      console.error("Error assigning incident to worker:", err);
    }
  };

  const handleMarkAsResolved = async () => {
    try {
      await updateIncidentStatus(incident.id, "resolved");
    } catch (err) {
      console.error("Error marking incident as resolved:", err);
    }
  };

  // Show loading state when either user or incident is loading
  if (userLoading || incidentLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="warning">Please log in to view this incident.</Alert>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  if (!incident) {
    return (
      <Container maxWidth="lg">
        <Box py={4}>
          <Alert severity="info">Incident not found</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleBack} sx={{ mr: 2 }}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h5">{incident.title}</Typography>
          </Box>
          {incident.status === "new" && incident.assigned_to === null && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlayArrow />}
              onClick={handleAssignToWorker}
            >
              Resolver Incidente
            </Button>
          )}
          {incident.status === "in_progress" && (
            <Button
              variant="contained"
              sx={{ backgroundColor: "status.resolved.main", color: "white" }} // Use green color for resolved status
              startIcon={<CheckCircle />}
              onClick={handleMarkAsResolved}
            >
              Marcar como Resuelto
            </Button>
          )}
        </Box>

        <IncidentDetailView
          incident={incident}
          onUpdateStatus={handleUpdateStatus}
          onAddComment={handleComment}
          onRateResolution={(rating, comment) => {
            // Implement rating
          }}
        />
      </Box>
    </Container>
  );
};

export default IncidentDetail;
