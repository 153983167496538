// src/features/incident/components/list/IncidentFilters.js
import React from 'react';
import { Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { 
  INCIDENT_STATUS, 
  INCIDENT_PRIORITY, 
  INCIDENT_CATEGORIES,
  STATUS_CONFIG,
  PRIORITY_CONFIG,
  CATEGORY_LABELS
} from '../../constants';

const IncidentFilters = ({ filters, onChange }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ ...filters, [name]: value });
  };

  return (
    <Stack direction="row" spacing={2}>
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel>Estado</InputLabel>
        <Select
          name="status"
          value={filters.status}
          label="Estado"
          onChange={handleChange}
        >
          <MenuItem value="">Todos</MenuItem>
          {Object.entries(INCIDENT_STATUS).map(([key, value]) => (
            <MenuItem key={value} value={value}>
              {STATUS_CONFIG[value].label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel>Prioridad</InputLabel>
        <Select
          name="priority"
          value={filters.priority}
          label="Prioridad"
          onChange={handleChange}
        >
          <MenuItem value="">Todas</MenuItem>
          {Object.entries(INCIDENT_PRIORITY).map(([key, value]) => (
            <MenuItem key={value} value={value}>
              {PRIORITY_CONFIG[value].label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel>Categoría</InputLabel>
        <Select
          name="category"
          value={filters.category}
          label="Categoría"
          onChange={handleChange}
        >
          <MenuItem value="">Todas</MenuItem>
          {Object.entries(INCIDENT_CATEGORIES).map(([key, value]) => (
            <MenuItem key={value} value={value}>
              {CATEGORY_LABELS[value]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default IncidentFilters;