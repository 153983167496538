// src/features/invitation/hooks/useVisits.js

import { useState, useCallback, useEffect } from 'react';
import { invitationService } from '../services/invitationService';
import { parseISO } from 'date-fns';
import useGlobalStore from '../../../state/store';

export const useVisits = () => {
  const [visits, setVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = useGlobalStore((state) => state.user);
  const userLoading = useGlobalStore((state) => state.loading);

  const fetchVisits = useCallback(async () => {
    if (!user || !user.id) {
      setError('No authenticated user');
      setLoading(false);
      return;
    }
  
    setLoading(true);
    setError(null);
    try {
      const data = await invitationService.fetchVisitsForConserje(user.id);
      const sortedVisits = data.sort((a, b) => {
        if (!a.invitation_date && !b.invitation_date) return 0;
        if (!a.invitation_date) return 1;
        if (!b.invitation_date) return -1;
        return parseISO(a.invitation_date) - parseISO(b.invitation_date);
      });
      setVisits(sortedVisits);
    } catch (err) {
      console.error('Error fetching visits:', err);
      setError(`Failed to fetch visits: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (!userLoading) {
      fetchVisits();
    }
  }, [userLoading, fetchVisits]);

  return { visits, loading: userLoading || loading, error, refetchVisits: fetchVisits };
};