// src/features/incident/utils/priorityCalculator.js
import { INCIDENT_PRIORITY } from '../constants';

const CATEGORY_WEIGHTS = {
  security: 5,
  elevator: 4,
  utilities: 4,
  maintenance: 3,
  noise: 2,
  cleaning: 2,
  common_areas: 2,
  parking: 2,
  other: 1
};

const EMERGENCY_KEYWORDS = {
  'emergencia': 5,
  'urgente': 4,
  'peligro': 5,
  'riesgo': 4,
  'fuga': 4,
  'incendio': 5,
  'robo': 5,
  'herido': 5,
  'sangre': 5,
  'ambulancia': 5,
  'policia': 5,
  'rotura': 3,
  'inundación': 4,
  'cortocircuito': 4
};

export const calculatePriority = (incidentData) => {
  let score = 0;

  // Category weight
  score += CATEGORY_WEIGHTS[incidentData.category] || 1;

  // Text analysis
  const text = `${incidentData.title} ${incidentData.description}`.toLowerCase();
  Object.entries(EMERGENCY_KEYWORDS).forEach(([keyword, weight]) => {
    if (text.includes(keyword)) {
      score += weight;
    }
  });

  // Time-based factors
  const hour = new Date().getHours();
  if (hour < 6 || hour > 22) {
    score += 2; // Night time incidents are more critical
  }

  // Calculate final priority
  if (score >= 10) return INCIDENT_PRIORITY.CRITICAL;
  if (score >= 7) return INCIDENT_PRIORITY.HIGH;
  if (score >= 4) return INCIDENT_PRIORITY.MEDIUM;
  return INCIDENT_PRIORITY.LOW;
};

export const calculateSLA = (priority) => {
  const now = new Date();
  switch (priority) {
    case INCIDENT_PRIORITY.CRITICAL:
      return new Date(now.getTime() + 4 * 60 * 60 * 1000); // 4 hours
    case INCIDENT_PRIORITY.HIGH:
      return new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours
    case INCIDENT_PRIORITY.MEDIUM:
      return new Date(now.getTime() + 72 * 60 * 60 * 1000); // 72 hours
    case INCIDENT_PRIORITY.LOW:
      return new Date(now.getTime() + 168 * 60 * 60 * 1000); // 7 days
    default:
      return new Date(now.getTime() + 72 * 60 * 60 * 1000);
  }
};