// src/features/incidents/components/shared/MediaGallery.js
import React, { useState, useEffect } from 'react';
import { Box, ImageList, ImageListItem, Skeleton, Typography } from '@mui/material';
import { supabaseStorage } from '../../../../storage';

export const MediaGallery = ({ media = [] }) => {
  const [loadingStates, setLoadingStates] = useState({});
  const [signedUrls, setSignedUrls] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchSignedUrls = async () => {
      const newSignedUrls = {};
      
      for (const item of media) {
        try {
          const path = item.path || item;
          if (typeof path === 'string') {
            const signedUrl = await supabaseStorage.getFileUrl(path, true);
            newSignedUrls[path] = signedUrl;
          }
        } catch (error) {
          console.error('Error getting signed URL:', error);
          setErrors(prev => ({
            ...prev,
            [item.path || item]: error.message
          }));
        }
      }

      setSignedUrls(newSignedUrls);
    };

    if (media.length > 0) {
      fetchSignedUrls();
    }
  }, [media]);

  const isImage = (mediaItem) => {
    if (!mediaItem) return false;
    const path = mediaItem.path || mediaItem;
    return /\.(jpg|jpeg|png|gif|webp)$/i.test(path.toLowerCase());
  };

  const getImageUrl = (mediaItem) => {
    const path = mediaItem.path || mediaItem;
    return signedUrls[path] || '';
  };

  const renderMedia = (mediaItem, index) => {
    if (!isImage(mediaItem)) return null;

    const path = mediaItem.path || mediaItem;
    const url = getImageUrl(mediaItem);
    const itemHeight = 200;
    const hasError = errors[path];

    if (hasError) {
      return (
        <Box
          sx={{
            height: itemHeight,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'background.paper',
            p: 2,
            borderRadius: 1
          }}
        >
          <Typography variant="caption" color="error" align="center">
            Error loading image
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: itemHeight,
          bgcolor: 'background.paper',
          borderRadius: 1,
          overflow: 'hidden'
        }}
      >
        {loadingStates[path] !== false && (
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            animation="wave"
            sx={{ position: 'absolute', top: 0, left: 0 }}
          />
        )}
        {url && (
          <Box
            component="img"
            src={url}
            alt={`Media ${index + 1}`}
            loading="lazy"
            onLoad={() => setLoadingStates(prev => ({ ...prev, [path]: false }))}
            onError={(e) => {
              console.error('Failed to load image:', url);
              setErrors(prev => ({ ...prev, [path]: 'Failed to load image' }));
              setLoadingStates(prev => ({ ...prev, [path]: false }));
            }}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 1
            }}
          />
        )}
      </Box>
    );
  };

  if (!media || media.length === 0) {
    return null;
  }

  return (
    <Box sx={{ 
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
    }}>
      <ImageList 
        variant="standard" 
        cols={2} 
        gap={8}
        sx={{
          overflow: 'hidden',
          m: 0, // Remove default margin
          width: '100%',
          // Ensure ImageListItems don't overflow
          '& .MuiImageListItem-root': {
            overflow: 'hidden',
            width: '100% !important', // Force width containment
          }
        }}
      >
        {media.filter(item => isImage(item)).map((item, index) => (
          <ImageListItem 
            key={item.path || item || index}
            sx={{
              overflow: 'hidden',
              borderRadius: 1
            }}
          >
            {renderMedia(item, index)}
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};