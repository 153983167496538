// src/features/maintenanceFee/hooks/useMaintenanceFee.js

import { useState, useEffect, useCallback } from 'react';
import { maintenanceFeeService } from '../services/maintenanceFeeService';
import useGlobalStore from '../../../state/store';
import { startOfMonth, endOfMonth, format } from 'date-fns';

export const useMaintenanceFee = (selectedDate) => {
  const user = useGlobalStore((s) => s.user);
  const userLoading = useGlobalStore((s) => s.loading); 
  const [apartments, setApartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMaintenanceData = useCallback(async () => {
    if (userLoading) return;
    setLoading(true);
    setError(null);
    try {
      const buildingId = user?.buildings?.[0]?.id;
      console.log('Building ID:', buildingId);
      if (!buildingId) {
        throw new Error('No building assigned');
      }

      // Parse selectedDate into a Date object
      const selectedDateObj = new Date(`${selectedDate}-01T12:00:00`);
      if (isNaN(selectedDateObj)) {
        throw new Error('Invalid selected date');
      }

      // Calculate start and end dates using date-fns
      const startDate = format(startOfMonth(selectedDateObj), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(selectedDateObj), 'yyyy-MM-dd');
      console.log('Date Range:', { startDate, endDate });

      // Get base apartment data first
      const apartmentData = await maintenanceFeeService.fetchApartmentsByBuildingId(buildingId);
      console.log('Fetched Apartments:', apartmentData);

      // Get charges and payments for the date range
      const [maintenanceData, paymentsData] = await Promise.all([
        maintenanceFeeService.fetchMaintenanceData(buildingId, selectedDate),
        maintenanceFeeService.fetchPayments(buildingId, { startDate, endDate })
      ]);

      // Get groups of charges and payments by apartment
      const chargesByApartment = {};
      maintenanceData.charges.forEach(charge => {
        if (!chargesByApartment[charge.apartmentNumber]) {
          chargesByApartment[charge.apartmentNumber] = [];
        }
        chargesByApartment[charge.apartmentNumber].push(charge);
      });

      const paymentsByApartment = {};
      paymentsData.payments.forEach(payment => {
        if (!paymentsByApartment[payment.apartmentId]) {
          paymentsByApartment[payment.apartmentId] = [];
        }
        paymentsByApartment[payment.apartmentId].push(payment);
      });

      // Combine everything into final apartment data
      const combinedData = apartmentData.map(apt => {
        const aptCharges = chargesByApartment[apt.apartmentNumber] || [];
        const aptPayments = paymentsByApartment[apt.id] || [];

        // Calculate emiCuota (only maintenance_fee charges)
        const emiCuota = aptCharges
          .filter(charge => charge.charge_type === 'maintenance_fee')
          .reduce((sum, charge) => sum + (charge.total_amount || 0), 0);
        
        // Calculate total charges (all types) for debt calculation
        const totalCharges = aptCharges
          .reduce((sum, charge) => sum + (charge.total_amount || 0), 0);

        const montoPagado = aptPayments
          .filter(p => p.status === 'verified')
          .reduce((sum, p) => sum + p.amount, 0);
        const porValidar = aptPayments
          .filter(p => p.status === 'pending')
          .reduce((sum, p) => sum + p.amount, 0);
        const deuda = Math.max(0, totalCharges - montoPagado);

        const residentName = apt.residents?.[0] ? 
          `${apt.residents[0].first_name} ${apt.residents[0].last_name}` : 
          'Sin residente';

        return {
          id: apt.id,
          apartmentNumber: apt.apartmentNumber,
          residentName,
          emiCuota,
          montoPagado,
          porValidar,
          deuda,
          payments: aptPayments, // This is what's shown in the expanded view
          charges: aptCharges  // Add charges to be available in the row component
        };
      });

      setApartments(combinedData);
    } catch (err) {
      console.error('Error fetching maintenance data:', err);
      setError(err.message || 'Error loading data');
    } finally {
      setLoading(false);
    }
  }, [user, selectedDate, userLoading]); 

  useEffect(() => {
    if (!userLoading && user?.buildings?.length > 0 && selectedDate) {
      fetchMaintenanceData();
    } else if (!userLoading && user && (!user.buildings || user.buildings.length === 0)) {
      setError('No building assigned');
      setLoading(false);
    }
  }, [user, selectedDate, fetchMaintenanceData, userLoading]);

  return {
    apartments,
    loading,
    error,
    fetchMaintenanceData,
  };
};