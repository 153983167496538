// src/features/incidents/components/detail/IncidentComments.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Avatar,
  Stack,
  Typography,
  Divider,
  IconButton,
  Alert,
  Snackbar
} from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { Send, Image } from 'lucide-react';
import { MediaUpload } from '../create';
import { MediaGallery } from '../shared/MediaGallery';

const IncidentComments = ({
  comments = [],
  onAddComment,
  incidentId,
  buildingId,
  loading = false
}) => {
  const [comment, setComment] = useState('');
  const [mediaFiles, setMediaFiles] = useState([]);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedComment = comment.trim();
    
    if (!trimmedComment && (!mediaFiles || mediaFiles.length === 0)) return;
    if (!buildingId) {
      setError('Missing building ID');
      return;
    }

    try {
      setError(null);
      console.log('Submitting comment:', {
        comment: trimmedComment,
        mediaFiles,
        incidentId,
        buildingId
      });
      
      await onAddComment(trimmedComment, mediaFiles);
      setComment('');
      setMediaFiles([]);
    } catch (error) {
      console.error('Error adding comment:', error);
      setError(error.message || 'Error al agregar el comentario');
    }
  };

  const handleFileSelect = (e) => {
    const newFiles = Array.from(e.target.files);
    setMediaFiles(prevFiles => [...prevFiles, ...newFiles]);
    // Reset file input value so same file can be selected again
    e.target.value = '';
  };

  // Add this to debug comments rendering
  useEffect(() => {
    console.log('Comments updated:', comments);
  }, [comments]);

  return (
    <Stack spacing={3} sx={{ 
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden'
    }}>
      <Typography variant="h6">
        Comentarios ({comments.length})
      </Typography>

      {/* Comment List */}
      <Stack spacing={2}>
        {[...comments]
          .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          .map((comment, index) => (
          <Box key={comment.id}>
            <Stack direction="row" spacing={2}>
              <Avatar
                src={comment.user?.profile_pic}
                alt={comment.user?.first_name}
              />
              <Stack spacing={1} flex={1}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="subtitle2">
                    {comment.user?.first_name} {comment.user?.last_name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatDistanceToNow(new Date(comment.created_at), {
                      addSuffix: true,
                      locale: es
                    })}
                  </Typography>
                </Box>
                <Typography variant="body2">
                  {comment.comment}
                </Typography>
                {comment.media_urls && comment.media_urls.length > 0 && (
                  <Box sx={{ 
                    width: '100%',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    mt: 1
                  }}>
                    <MediaGallery media={comment.media_urls} />
                  </Box>
                )}
              </Stack>
            </Stack>
            {index < comments.length - 1 && <Divider sx={{ my: 2 }} />}
          </Box>
        ))}
      </Stack>

      {/* Comment Form */}
      <Box component="form" onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            multiline
            rows={2}
            placeholder="Escribe un comentario..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            disabled={loading}
            error={!!error}
            helperText={error}
            InputProps={{
              endAdornment: (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <input
                    type="file"
                    multiple
                    hidden
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    accept="image/*,video/*"
                  />
                  <IconButton
                    onClick={() => fileInputRef.current.click()}
                    color={mediaFiles.length > 0 ? 'primary' : 'default'}
                  >
                    <Image size={20} />
                  </IconButton>
                  <IconButton 
                    type="submit" 
                    disabled={loading || (!comment.trim() && mediaFiles.length === 0)}
                  >
                    <Send size={20} />
                  </IconButton>
                </Box>
              )
            }}
          />

          {mediaFiles.length > 0 && (
            <MediaUpload
              files={mediaFiles}
              onChange={setMediaFiles}
              disabled={loading}
              prefix={`incidents/${incidentId}/comments`}
              hideAttachButton={true}
            />
          )}
        </Stack>
      </Box>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default IncidentComments;