// src/features/dashboard/components/Sidebar.js

import React from "react";
import { useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  People,
  CalendarToday,
  LocalLaundryService,
  Room,
  LocalShipping,
  Chat,
  Warning,
  MonetizationOn,
  OpacityOutlined,
} from "@mui/icons-material";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";

const menuItems = [
  { text: "Visitas", icon: <People />, route: ROUTES.VISITS, premium: false },
  {
    text: "Reservas",
    icon: <CalendarToday />,
    route: ROUTES.RESERVATIONS,
    premium: true,
  },
  {
    text: "Lavandería",
    icon: <LocalLaundryService />,
    route: ROUTES.LAUNDRY,
    premium: true,
  },
  {
    text: "Áreas comunes",
    icon: <Room />,
    route: ROUTES.COMMON_AREAS,
    premium: true,
  },
  {
    text: "Delivery",
    icon: <LocalShipping />,
    route: ROUTES.DELIVERY,
    premium: true,
  },
  {
    text: "Residentes",
    icon: <People />,
    route: ROUTES.RESIDENTS,
    premium: true,
  },
  { text: "Chat", icon: <Chat />, route: ROUTES.CHAT, premium: true },
  {
    text: "Incidencias",
    icon: <Warning />,
    route: ROUTES.INCIDENTS,
    premium: false,
  },
  // {
  //   text: "Multas",
  //   icon: <MonetizationOn />,
  //   route: ROUTES.FINES,
  //   premium: true,
  // },
  {
    text: "Contadores",
    icon: <OpacityOutlined />,
    route: ROUTES.METERS,
    premium: false, //to do: Poner la limitación de acuerdo al contexto
  },
  {
    text: "Contabilidad",
    icon: <MonetizationOn />,
    route: ROUTES.MAINTENANCE_FEE,
    premium: false,
  },
];

const Sidebar = ({ open, sx, onClose, onPremiumFeatureClick }) => {
  const { navigateWithParams } = useNavigation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();

  const handleItemClick = (item) => {
    if (!item.premium) {
      navigateWithParams(item.route);
      if (isMobile) {
        onClose();
      }
    } else {
      onPremiumFeatureClick();
    }
  };

  return (
    <Drawer
      variant={isMobile ? "temporary" : "persistent"}
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        ...sx,
        "& .MuiDrawer-paper": {
          ...sx["& .MuiDrawer-paper"],
          position: "fixed",
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <List>
        {menuItems.map((item) => (
          <ListItem
            disableGutters
            key={item.text}
            onClick={() => handleItemClick(item)}
            sx={{
              paddingLeft: 4,
              bgcolor: item.route === location.pathname ? "#E9EDFF" : "inherit",
              color:
                item.route === location.pathname
                  ? "#315CAC"
                  : theme.palette.text.primary,
              "&.Mui-disabled": {
                opacity: 0.5,
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: item.route === location.pathname ? "#315CAC" : "inherit",
                minWidth: "40px",
              }}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                fontWeight:
                  item.route === location.pathname ? "bold" : "normal",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
