// src/features/invitation/components/FutureVisits.js

import React from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';
import { CalendarToday, AccessTime, Apartment } from '@mui/icons-material';
import { format, isAfter, parseISO, startOfDay, addDays } from 'date-fns';
import { es } from 'date-fns/locale';
import NoVisitsMessage from './NoVisitsMessage';

const FutureVisits = ({ visits }) => {
  const tomorrow = startOfDay(addDays(new Date(), 1));
  const futureVisits = visits.filter(visit => 
    visit.invitation_date && isAfter(parseISO(visit.invitation_date), tomorrow)
  );

  if (futureVisits.length === 0) {
    return <NoVisitsMessage isActiveTab={true} />;
  }

  const groupedVisits = futureVisits.reduce((acc, visit) => {
    const date = format(parseISO(visit.invitation_date), 'yyyy-MM-dd');
    if (!acc[date]) acc[date] = [];
    acc[date].push(visit);
    return acc;
  }, {});

  return Object.entries(groupedVisits).map(([date, dateVisits]) => (
    <Box key={date} sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CalendarToday sx={{ mr: 1 }} />
          <Typography variant="h6">
            {format(parseISO(date), "EEEE, d 'de' MMMM", { locale: es })}
          </Typography>
        </Box>
        <Typography variant="subtitle1" sx={{ ml: 2, color: 'text.secondary' }}>
          {dateVisits.length} visitas
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {dateVisits.map(visit => (
          <Grid item xs={12} key={visit.visit_id}>
            <Card sx={{ 
              '&:hover': { 
                boxShadow: 3,
                transform: 'translateY(-2px)',
                transition: 'all 0.3s'
              }
            }}>
              <CardContent>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Typography 
                      variant="subtitle1" 
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        lineHeight: '1.2em',
                        height: '2.4em'
                      }}
                    >
                      {visit.visitor_first_name} {visit.visitor_last_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Apartment sx={{ mr: 1, fontSize: 20, color: 'text.secondary' }} />
                      <Typography variant="body2" color="text.secondary">
                        Depa {visit.apartment_number}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                      <AccessTime sx={{ mr: 1, fontSize: 20, color: 'text.secondary' }} />
                      <Typography variant="body2" color="text.secondary">
                        {format(parseISO(visit.invitation_date), "HH:mm")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  ));
};

export default FutureVisits;