// src/features/manualVisitRegistration/components/PhoneInput.js

import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Box } from '@mui/material';

const PhoneInputComponent = ({ value, onChange }) => {
  return (
    <Box
      sx={{
        '& .react-tel-input': {
          marginTop: '16px',
        },
        '& .react-tel-input .special-label': {
          display: 'none',
        },
        '& .react-tel-input .form-control': {
          width: '100%',
          height: '56px',
          fontSize: '1rem',
          borderRadius: '16px',
          backgroundColor: 'white',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:focus': {
            borderColor: 'primary.main',
            borderWidth: '2px',
            boxShadow: 'none',
          },
        },
        '& .react-tel-input .flag-dropdown': {
          backgroundColor: 'transparent',
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        '& .react-tel-input .selected-flag': {
          backgroundColor: 'transparent',
          '&:hover, &:focus': {
            backgroundColor: 'transparent',
          },
        },
        '& .react-tel-input .country-list': {
          margin: '8px 0 0 -1px',
        },
      }}
    >
      <PhoneInput
        country={'pe'}
        value={value}
        onChange={onChange}
        inputProps={{
          required: true,
        }}
      />
    </Box>
  );
};

export default PhoneInputComponent;