// src/features/invitation/components/StatusChip.js

import React from 'react';
import { Chip, styled } from '@mui/material';

const StyledChip = styled(Chip)(({ theme, status }) => ({
  fontWeight: 'bold',
  fontSize: '0.75rem',
  ...(status === 'pending' && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  }),
  ...(status === 'checked_in' && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  }),
  ...(status === 'cancelled' && {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  }),
}));

const StatusChip = ({ status }) => {
  const getLabel = (status) => {
    switch (status) {
      case 'pending':
        return 'Pendiente';
      case 'checked_in':
        return 'Registrado';
      case 'cancelled':
        return 'Cancelado';
      default:
        return status;
    }
  };

  return (
    <StyledChip
      label={getLabel(status)}
      status={status}
      size="small"
    />
  );
};

export default StatusChip;