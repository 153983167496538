// src/features/maintenanceFee/components/maintenanceFeeDetail/MaintenanceFeeDetailHeader.js

import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const MaintenanceFeeDetailHeader = ({ onBack }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <IconButton
        onClick={onBack}
        sx={{
          color: '#111827',
          '&:hover': {
            backgroundColor: '#E5E7EB',
          },
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      <Box>
        <Typography sx={{ fontWeight: 600, fontSize: '20px', color: '#111827' }}>
          Detalle cuota de mantenimiento
        </Typography>
      </Box>
    </Box>
  );
};

export default MaintenanceFeeDetailHeader;