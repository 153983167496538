// src/features/manualVisitRegistration/components/DocumentInput.js

import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const DocumentInput = ({ onSubmit }) => {
  const [documentNumber, setDocumentNumber] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(documentNumber);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Número de documento
      </Typography>
      <TextField
        required
        label="Número de documento"
        value={documentNumber}
        onChange={(e) => setDocumentNumber(e.target.value)}
        fullWidth
        margin="normal"
        placeholder="Ingrese el número de documento"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
        Buscar
      </Button>
    </Box>
  );
};

export default DocumentInput;