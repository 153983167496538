import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Alert,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import { Plus } from "lucide-react";
import { IncidentList, IncidentTable } from "../components/list";
import { useIncident } from "../hooks/useIncident";
import { useNavigation } from "../../../navigation/hooks/useNavigation";
import { ROUTES } from "../../../config/routes";
import { incidentService } from "../services/incidentService";
import { isWithinInterval, startOfWeek, endOfWeek } from "date-fns";
import { incidentStyles } from "../../../styles/featuresStyles/incidentStyles";
import useGlobalStore from "../../../state/store";

const IncidentMain = () => {
  const { navigateWithParams, getParams } = useNavigation();

  const user = useGlobalStore((state) => state.user);
  const userLoading = useGlobalStore((state) => state.loading);
  
  const { updateIncidentStatus } = useIncident();
  const [incidents, setIncidents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  // Move hooks to the top level
  const fetchIncidents = useCallback(async () => {
    if (userLoading || !user?.id) return;

    try {
      setLoading(true);
      setError(null);

      const buildingId = user.buildings?.[0]?.id;
      if (!buildingId) {
        console.warn("No building ID found");
        setLoading(false);
        return;
      }

      const response = await incidentService.fetchBuildingIncidents(
        buildingId,
        user.id
      );
      const incidents = Array.isArray(response) ? response : response?.data;

      if (!incidents) {
        console.warn("Invalid response format:", response);
        setIncidents([]);
        return;
      }

      setIncidents(incidents);
    } catch (err) {
      console.error("Error fetching incidents:", err);
      setError(err.message || "Error loading incidents");
    } finally {
      setLoading(false);
    }
  }, [user, userLoading]);

  // Effect hook at top level
  useEffect(() => {
    fetchIncidents();
  }, [fetchIncidents]);

  const isWithinCurrentWeek = useCallback((date) => {
    if (!date) return false;
    const now = new Date();
    const weekStart = startOfWeek(now);
    const weekEnd = endOfWeek(now);
    return isWithinInterval(new Date(date), { start: weekStart, end: weekEnd });
  }, []);

  // Move memo to top level
  const { activeIncidents, resolvedIncidents } = useMemo(() => {
    const active = incidents.filter(
      (inc) => !inc.resolution_date || !isWithinCurrentWeek(inc.resolution_date)
    );
    const resolved = incidents.filter(
      (inc) => inc.resolution_date && isWithinCurrentWeek(inc.resolution_date)
    );
    return { activeIncidents: active, resolvedIncidents: resolved };
  }, [incidents, isWithinCurrentWeek]);

  const handleStatusChange = async (incidentId, newStatus) => {
    try {
      await updateIncidentStatus(incidentId, newStatus);
      fetchIncidents();
    } catch (err) {
      setError(err.message || "Error updating incident status");
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCreateNew = () => {
    const params = getParams();
    navigateWithParams(ROUTES.INCIDENT_NEW, params);
  };

  const handleIncidentClick = (incidentId) => {
    const params = getParams();
    navigateWithParams(`${ROUTES.INCIDENTS}/${incidentId}`, params);
  };

  if (userLoading || loading) {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={incidentStyles.container}>
        <Box sx={incidentStyles.header}>
          <Typography
            variant="h5"
            component="h1"
            sx={incidentStyles.headerTitle}
          >
            Mis Incidencias
          </Typography>
          <Button
            variant="contained"
            startIcon={<Plus />}
            onClick={handleCreateNew}
            sx={incidentStyles.addButton}
          >
            Nueva Incidencia
          </Button>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="incident views"
          sx={incidentStyles.tabs}
          centered
        >
          <Tab label="En Proceso" />
          <Tab label="Resueltas" />
        </Tabs>

        <Box sx={incidentStyles.listContainer}>
          {tabValue === 0 ? (
            <IncidentList
              incidents={activeIncidents}
              onStatusChange={handleStatusChange}
              onIncidentClick={handleIncidentClick}
            />
          ) : (
            <IncidentTable
              incidents={resolvedIncidents}
              onStatusChange={handleStatusChange}
              onIncidentClick={handleIncidentClick}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default IncidentMain;
