// src/shared/layouts/MainLayout.js

import React, { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
  Button,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Outlet } from "react-router-dom";
import ProfileMenu from "../components/ProfileMenu";
import Sidebar from "../../features/dashboard/components/Sidebar";
import PremiumFeaturePopup from "../../features/dashboard/components/PremiumFeaturePopup";
import { authService } from "../../features/auth/services/authService"; // Corrected import path
import useGlobalStore from "../../state/store";

const MainLayout = () => {
  const user = useGlobalStore((state) => state.user);
  const loading = useGlobalStore((state) => state.loading);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const [popupOpen, setPopupOpen] = useState(false);
  const drawerWidth = 240;

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handlePremiumFeatureClick = () => {
    setPopupOpen(true);
  };

  // Check for worker roles
  const hasWorkerAccess =
    user?.is_worker && (user.roles.includes("concierge") || user.roles.includes("manager"));

  if (!loading && !hasWorkerAccess) {
    return (
      <Box sx={{ p: 4, textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          Acceso no autorizado
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Esta cuenta no tiene permisos de trabajador (conserje o administrador).
        </Typography>
        <Button variant="contained" onClick={() => authService.signOut()} sx={{ mt: 2 }}>
          Cerrar sesión
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleSidebarToggle}
              sx={{ mr: 2, color: "primary.main" }}
            >
              <MenuIcon />
            </IconButton>
            <img src={"/images/domus.png"} alt="Domus" style={{ height: "40px" }} />
          </Box>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
      <Box sx={{ display: "flex", flexGrow: 1, pt: "64px" }}>
        {user && (
          <Sidebar
            open={sidebarOpen}
            onClose={() => setSidebarOpen(false)}
            onPremiumFeatureClick={handlePremiumFeatureClick}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                top: 64,
                height: "calc(100% - 64px)",
              },
            }}
          />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: "100%",
            p: 3,
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <PremiumFeaturePopup open={popupOpen} onClose={() => setPopupOpen(false)} />
    </Box>
  );
};

export default MainLayout;
