import React from "react";
import {
  Container,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
  Button,
} from "@mui/material";

const MeterMain = () => {
  // Datos estáticos de ejemplo para renderizar en la tabla
  const meterData = [
    { department: "2002", period: "Diciembre 2024", initial: "4,952.09", final: "5,952.09", consumption: "1,000" },
    { department: "2012", period: "Diciembre 2024", initial: "10,500", final: "11,200", consumption: "700" },
    { department: "2022", period: "Diciembre 2024", initial: "9,000", final: "10,000", consumption: "1,000" },
    { department: "2033", period: "Diciembre 2024", initial: "9,000", final: "9,500", consumption: "500" },
    { department: "2052", period: "Diciembre 2024", initial: "9,000", final: "9,700", consumption: "700" },
    { department: "2010", period: "Diciembre 2024", initial: "9,000", final: "9,800", consumption: "800" },
    { department: "2003", period: "Diciembre 2024", initial: "9,000", final: "11,000", consumption: "2000" },
    { department: "2027", period: "Diciembre 2024", initial: "9,000", final: "9,200", consumption: "200" },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h5" fontWeight="bold">
            Contadores
          </Typography>
          <Button
                    variant="outlined"
                    sx={{
                      color: '#4F46E5',
                      borderColor: '#E0E7FF',
                      backgroundColor: '#F3F4F6',
                      borderRadius: '24px',
                      textTransform: 'none',
                      fontSize: '14px',
                      fontWeight: 500,
                      '&:hover': {
                        backgroundColor: '#E0E7FF',
                        borderColor: '#4F46E5',
                      },
                    }}
                  >
                    Exportar
                  </Button>
        </Box>
        {/* Selector de período */}
        <Select defaultValue="Diciembre 2024" size="small" sx={{ minWidth: 150 }}>
          <MenuItem value="Diciembre 2024">Diciembre 2024</MenuItem>
          <MenuItem value="Noviembre 2024">Noviembre 2024</MenuItem>
          <MenuItem value="Octubre 2024">Octubre 2024</MenuItem>
        </Select>
      </Box>

      {/* Tabla de Contadores */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#f3f4f6" }}>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>Departamento</TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>Período</TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>Lectura inicial (m3)</TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>Lectura final (m3)</TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>Consumo (m3)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {meterData.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="left">{row.department}</TableCell>
                <TableCell align="left">{row.period}</TableCell>
                <TableCell align="left">{row.initial}</TableCell>
                <TableCell align="left">{row.final}</TableCell>
                <TableCell align="left">{row.consumption}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default MeterMain;
