// src/features/maintenanceFee/components/MaintenanceFeeHeader.js

import React from 'react';
import { Box, Typography, Button, Select, MenuItem } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { format, addMonths } from 'date-fns';
import { es } from 'date-fns/locale';

const MaintenanceFeeHeader = ({ selectedDate, onDateChange }) => {
  const getMonthOptions = () => {
    const options = [];
    const today = new Date();

    for (let i = -5; i <= 5; i++) {
      const date = addMonths(today, i);
      options.push({
        value: format(date, 'yyyy-MM'),
        label: format(date, 'MMMM yyyy', { locale: es }).charAt(0).toUpperCase() + format(date, 'MMMM yyyy', { locale: es }).slice(1),
      });
    }

    return options;
  };

  const handleDateChange = (value) => {
    console.log('Dropdown Selected Date:', value);
    onDateChange(value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      {/* Top Section: Title and Configurations Button */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Icon and Title */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <ReceiptIcon sx={{ fontSize: 28, color: '#315CAC' }} />
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 600,
              color: '#315CAC',
            }}
          >
            Cuota de Mantenimiento
          </Typography>
        </Box>

        {/* Configurations Button */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#315CAC',
            color: 'white',
            borderRadius: '24px',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            padding: '6px 16px',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            '&:hover': {
              backgroundColor: '#2A4F94',
            },
          }}
        >
          Configuraciones
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: '#FFFFFF',
              color: '#315CAC',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            3
          </Box>
        </Button>
      </Box>

      {/* Centered Month Selector */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {/* Left Line */}
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: '50%',
            height: '1px',
            width: '40%',
            backgroundColor: '#E5E7EB',
            transform: 'translateY(-50%)',
          }}
        />

        {/* Dropdown Selector */}
        <Select
          value={selectedDate || format(new Date(), 'yyyy-MM')}
          onChange={(e) => handleDateChange(e.target.value)}
          IconComponent={ArrowDropDownIcon}
          sx={{
            textAlign: 'center',
            minWidth: 180,
            maxWidth: 200,
            fontSize: '14px',
            fontWeight: 500,
            color: '#315CAC',
            border: '1px solid #D1D5DB',
            borderRadius: '24px',
            padding: '4px 12px',
            backgroundColor: 'white',
            '& .MuiSelect-icon': {
              color: '#315CAC',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover': {
              backgroundColor: '#F3F4F6',
            },
          }}
        >
          {getMonthOptions().map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>

        {/* Right Line */}
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            height: '1px',
            width: '40%',
            backgroundColor: '#E5E7EB',
            transform: 'translateY(-50%)',
          }}
        />
      </Box>
    </Box>
  );
};

export default MaintenanceFeeHeader;