// src/features/incident/components/list/IncidentCard.js
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material';
import { MoreVertical, MessageSquare, Image } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { StatusBadge, PriorityBadge } from '../shared';

const IncidentCard = ({
  incident,
  onStatusChange,
  onClick,
  showActions = true
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (newStatus) => {
    handleClose();
    onStatusChange?.(incident.id, newStatus);
  };

  const mediaCount = incident.media_urls?.length || 0;
  const commentCount = incident.comments?.length || 0;

  return (
    <Card 
      sx={{ cursor: 'pointer' }}
      onClick={() => onClick(incident.id)}
    >
      <CardContent>
        <div>
          <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Stack spacing={1}>
                <Typography variant="h6">
                  {incident.title}
                </Typography>
                <Stack direction="row" spacing={1}>
                  <StatusBadge status={incident.status} />
                  <PriorityBadge priority={incident.priority} />
                </Stack>
              </Stack>

              {showActions && (
                <IconButton onClick={handleClick}>
                  <MoreVertical />
                </IconButton>
              )}
            </Box>

            <Typography variant="body2" color="text.secondary">
              {incident.description}
            </Typography>

            {incident.location_details && (
              <Typography variant="body2" color="text.secondary">
                📍 {incident.location_details}
              </Typography>
            )}

            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                {mediaCount > 0 && (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Image size={16} />
                    <Typography variant="caption">
                      {mediaCount}
                    </Typography>
                  </Stack>
                )}
                {commentCount > 0 && (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <MessageSquare size={16} />
                    <Typography variant="caption">
                      {commentCount}
                    </Typography>
                  </Stack>
                )}
              </Stack>

              <Typography variant="caption" color="text.secondary">
                {formatDistanceToNow(new Date(incident.created_at), {
                  addSuffix: true,
                  locale: es
                })}
              </Typography>
            </Box>
          </Stack>
        </div>
      </CardContent>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={() => handleStatusChange('in_progress')}>
          Marcar en proceso
        </MenuItem> */}
        <MenuItem onClick={() => handleStatusChange('resolved')}>
          Marcar como resuelto
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default IncidentCard;