// src/features/auth/services/enhancedAuthService.js
import { supabase } from '../../../supabase/client';
import { authTokenService } from './authTokenService';
import { authService } from './authService';

class EnhancedAuthService {
  async signInWithPassword({ email, password }) {
    try {
      console.log('Starting login process for email:', email);
      const { data: { session }, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) throw error;

      // Use authTokenService to manage session
      authTokenService.setSession(session);

      if (!session.user) {
        throw new Error('User is null');
      }

      const userData = await authService.getUserProfile(session.user.id);

      return {
        data: {
          user: { ...session.user, ...userData },
          session
        }
      };
    } catch (error) {
      console.error('Sign in error:', error);
      return { error };
    }
  }

  async signOut() {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      authTokenService.clearSession();
      return { error: null };
    } catch (error) {
      console.error('Sign out error:', error);
      return { error };
    }
  }

  async getCurrentSession() {
    return authTokenService.getSession();
  }

  getAuthHeaders() {
    const token = authTokenService.getAccessToken();
    return token ? {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    } : {};
  }
}

export const enhancedAuthService = new EnhancedAuthService();