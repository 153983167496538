// src/features/dashboard/hooks/useDashboardAnalytics.js

import { useCallback } from 'react';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { EVENT_TYPES } from '../../../analytics/config/events';

export const useDashboardAnalytics = () => {
  const { track } = useAnalytics();

  const trackDashboardViewed = useCallback((userId) => {
    track(EVENT_TYPES.DASHBOARD_VIEWED, { userId });
  }, [track]);

  const trackWidgetInteraction = useCallback((widgetName, action, userId) => {
    track(EVENT_TYPES.DASHBOARD_WIDGET_INTERACTION, { widgetName, action, userId });
  }, [track]);

  return {
    trackDashboardViewed,
    trackWidgetInteraction,
  };
};