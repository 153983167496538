// src/supabase/services/realtimeService.js
import { supabase } from '../client';

class RealtimeService {
  constructor() {
    this.channels = new Map();
    this.isConnected = false;
    this.connectionRetries = 0;
    this.maxRetries = 3;
    this.retryDelay = 2000;
    this.reconnectTimeout = null;
  }

  async connect() {
    if (this.isConnected) return;

    try {
      await supabase.removeAllChannels();
      
      const { error } = await supabase.channel('system')
        .subscribe(async (status) => {
          if (status === 'SUBSCRIBED') {
            this.isConnected = true;
            this.connectionRetries = 0;
            console.log('Realtime connection established');
          } else if (status === 'CLOSED' || status === 'CANCELLED') {
            this.handleDisconnect();
          }
        });

      if (error) throw error;
    } catch (error) {
      console.error('Realtime connection error:', error);
      this.handleConnectionError();
    }
  }

  handleDisconnect() {
    this.isConnected = false;
    // Clear existing timeout if any
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    // Attempt to reconnect
    this.reconnectTimeout = setTimeout(() => {
      this.connect();
    }, this.retryDelay);
  }

  async handleConnectionError() {
    if (this.connectionRetries < this.maxRetries) {
      this.connectionRetries++;
      console.log(`Retrying connection (${this.connectionRetries}/${this.maxRetries})`);
      
      // Exponential backoff
      const delay = this.retryDelay * Math.pow(2, this.connectionRetries - 1);
      await new Promise(resolve => setTimeout(resolve, delay));
      await this.connect();
    } else {
      console.error('Max connection retries reached');
      this.isConnected = false;
    }
  }

  async disconnect() {
    try {
      if (this.reconnectTimeout) {
        clearTimeout(this.reconnectTimeout);
      }

      // Remove all channels
      for (const channel of this.channels.values()) {
        await channel.unsubscribe();
      }
      this.channels.clear();

      await supabase.removeAllChannels();
      this.isConnected = false;
      console.log('Realtime connection closed');
    } catch (error) {
      console.error('Error disconnecting realtime:', error);
    }
  }

  async subscribeToChannel(channelName, options = {}) {
    if (!this.isConnected) {
      await this.connect();
    }

    if (this.channels.has(channelName)) {
      return this.channels.get(channelName);
    }

    try {
      const channel = supabase.channel(channelName, {
        ...options,
        retryIntervalMs: 5000,
        timeout: 10000
      });
      
      channel
        .on('system', { event: '*' }, (payload) => {
          console.log('System event:', payload);
        })
        .on('presence', { event: 'sync' }, () => {
          console.log('Channel presence sync:', channelName);
        })
        .on('presence', { event: 'join' }, ({ key, newPresences }) => {
          console.log('Join:', key, newPresences);
        })
        .on('presence', { event: 'leave' }, ({ key, leftPresences }) => {
          console.log('Leave:', key, leftPresences);
        })
        .subscribe(async (status) => {
          console.log(`Channel ${channelName} status:`, status);
          if (status === 'SUBSCRIBED') {
            this.channels.set(channelName, channel);
          } else if (status === 'CLOSED' || status === 'CANCELLED') {
            this.channels.delete(channelName);
            // Attempt to resubscribe
            await this.resubscribeChannel(channelName, options);
          }
        });

      return channel;
    } catch (error) {
      console.error(`Error subscribing to channel ${channelName}:`, error);
      throw error;
    }
  }

  async resubscribeChannel(channelName, options) {
    if (this.connectionRetries < this.maxRetries) {
      this.connectionRetries++;
      const delay = this.retryDelay * Math.pow(2, this.connectionRetries - 1);
      await new Promise(resolve => setTimeout(resolve, delay));
      await this.subscribeToChannel(channelName, options);
    }
  }

  getChannel(channelName) {
    return this.channels.get(channelName);
  }
}

export const realtimeService = new RealtimeService();