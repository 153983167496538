// src/features/incident/components/list/IncidentList.js
import React, { useState, useCallback } from "react";
import { Stack } from "@mui/material";
import IncidentCard from "./IncidentCard";
import IncidentFilters from "./IncidentFilters";
import { NoIncidentsMessage } from "./";

const IncidentList = ({ incidents, onStatusChange, onIncidentClick }) => {
  const [filters, setFilters] = useState({
    status: "",
    priority: "",
    category: "",
  });

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const getFilteredIncidents = useCallback(() => {
    return incidents.filter((incident) => {
      if (filters.status && incident.status !== filters.status) return false;
      if (filters.priority && incident.priority !== filters.priority)
        return false;
      if (filters.category && incident.category !== filters.category)
        return false;
      return true;
    });
  }, [incidents, filters]);

  const filteredIncidents = getFilteredIncidents();

  return (
    <Stack spacing={3}>
      <IncidentFilters filters={filters} onChange={handleFilterChange} />

      {filteredIncidents.length === 0 ? (
        <NoIncidentsMessage isActiveTab={true} />
      ) : (
        <Stack spacing={2}>
          {filteredIncidents.map((incident) => (
            <IncidentCard
              key={incident.id}
              incident={incident}
              onStatusChange={onStatusChange}
              onClick={() => onIncidentClick(incident.id)}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default IncidentList;
