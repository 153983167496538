// src/shared/hooks/useUrlParams.ts

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useGlobalStore from '../../state/store';

export const useUrlParams = () => {
  const location = useLocation();

  const setInvitationToken = useGlobalStore((state) => state.setInvitationToken);
  const setBuildingId = useGlobalStore((state) => state.setBuildingId);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const buildingId = searchParams.get('buildingId');

    if (token)
    {
      setInvitationToken(token);
    }

    if (buildingId)
    {
      setBuildingId(buildingId);
    }
  }, [location, setInvitationToken, setBuildingId]);
};