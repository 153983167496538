// src/features/maintenanceFee/pages/MaintenanceFee.js

import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import MaintenanceFeeHeader from '../components/maintenanceFeeDashboard/MaintenanceFeeHeader';
import MaintenanceFeeStats from '../components/maintenanceFeeDashboard/MaintenanceFeeStats';
import MaintenanceFeeRow from '../components/maintenanceFeeDashboard/MaintenanceFeeRow';
import MaintenanceFeeTable from '../components/common/MaintenanceFeeTable';
import EmptyStateAlert from '../components/common/EmptyStateAlert';
import { useMaintenanceFee } from '../hooks/useMaintenanceFee';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import useGlobalStore from '../../../state/store'; // Add this import

const MaintenanceFee = () => {
  const [selectedDate, setSelectedDate] = useState(format(new Date(), 'yyyy-MM'));
  const { apartments, loading: maintenanceLoading, error, fetchMaintenanceData } = useMaintenanceFee(selectedDate);
  const userLoading = useGlobalStore((state) => state.loading);  // Now this will work
  const [searchTerm, setSearchTerm] = useState('');
  const [filterValue, setFilterValue] = useState('Todos');

  const navigate = useNavigate();

  // Parse selectedDate to get currentMonthYear
  const periodoDate = new Date(`${selectedDate}-01T12:00:00`);
  const currentMonthYear = format(periodoDate, 'MMMM yyyy', { locale: es })
    .split(' ')
    .map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word)
    .join(' ');
  console.log('Current Month Year:', currentMonthYear);

  useEffect(() => {
    fetchMaintenanceData(selectedDate);
  }, [selectedDate, fetchMaintenanceData]);

  if (userLoading || maintenanceLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ p: 3 }}>
        {error}
      </Typography>
    );
  }

  // Filter apartments based on search term and filter value
  const filteredApartments = apartments.filter((apt) => {
    const matchesSearch =
      apt.apartmentNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      apt.residents.some((resident) =>
        `${resident.first_name} ${resident.last_name}`.toLowerCase().includes(searchTerm.toLowerCase())
      );

    const matchesFilter =
      filterValue === 'Todos' ||
      (filterValue === 'Validados' && apt.porValidar === 0) ||
      (filterValue === 'Pendientes' && apt.porValidar > 0);

    return matchesSearch && matchesFilter;
  });

  console.log('Filtered Apartments:', filteredApartments);

  // Calculate Stats
  const stats = {
    pagosRecibidos: apartments.reduce((sum, apt) => sum + (apt.montoPagado || 0), 0),
    porValidar: apartments.reduce((sum, apt) => sum + (apt.porValidar || 0), 0),
    recaudado: apartments.reduce((sum, apt) => sum + (apt.montoPagado || 0), 0),
    total: apartments.reduce((sum, apt) => sum + (apt.emiCuota || 0), 0),
  };

  // Handler for EmptyStateAlert button click
  const handleAddCharges = () => {
    // Implement the logic to add charges
    // For example, navigate to a form page or open a modal
    console.log('Add Charges button clicked');
    // Example: Navigate to an "Add Charges" page
    navigate('/maintenance-fee/add'); // Adjust the path as per your routing
  };

  // Determine if all apartments have no charges
  const noCharges = apartments.length > 0 && apartments.every((apt) => apt.charges.length === 0);
  
  return (
    <Box sx={{ padding: 4 }}>
      {/* Header */}
      <Box sx={{ marginBottom: 4 }}>
        <MaintenanceFeeHeader 
          selectedDate={selectedDate} 
          onDateChange={setSelectedDate} 
        />
      </Box>

      {/* Conditionally render EmptyStateAlert if all apartments have no charges */}
      {noCharges && (
        <Box sx={{ marginTop: 2 }}>
          <EmptyStateAlert
            message={
              <>
                <span style={{ fontWeight: 500 }}>
                  No se han registrado cuotas de mantenimiento para el mes en curso,{' '}
                </span>
                <span style={{ fontWeight: 600, color: '#92400E' }}>{currentMonthYear}</span>.
              </>
            }
            buttonLabel="Añadir Cuotas"
            onButtonClick={handleAddCharges}
          />
        </Box>
      )}

      {/* Stats Section */}
      <Box sx={{ marginTop: 4 }}>
        <MaintenanceFeeStats
          selectedDate={selectedDate}
          data={stats}
        />
      </Box>

      {/* Table Section */}
      <Box sx={{ marginTop: 4 }}>
        <MaintenanceFeeTable
          onSearchChange={setSearchTerm}
          filterValue={filterValue}
          onFilterChange={setFilterValue}
        />
      </Box>

      {/* Apartments List as Rows */}
      <Box sx={{ marginTop: 4 }}>
        {filteredApartments.length > 0 ? (
          filteredApartments.map((apartment) => (
            <MaintenanceFeeRow key={apartment.id} apartment={apartment} />
          ))
        ) : (
          // If there are apartments but none match the filters/search
          <EmptyStateAlert
            message="No se encontraron apartamentos que coincidan con los criterios de búsqueda."
            buttonLabel="Limpiar Filtros"
            onButtonClick={() => {
              setSearchTerm('');
              setFilterValue('Todos');
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default MaintenanceFee;