// src/features/manualVisitRegistration/services/manualVisitRegistrationService.js

import { secureApi } from '../../../supabase/secureApi';

export const manualVisitRegistrationService = {
  
  findVisitorByDocument: async (documentNumber) => {
    try {
      const data = await secureApi.get('users', {
        filter: { document_number: documentNumber },
        single: true
      });
      return data || null;
    } catch (error) {
      if (error.code !== 'PGRST116') throw error;
      return null;
    }
  },

  createVisitor: async (visitorInfo) => {
    try {
      const userId = await secureApi.rpc('register_manual_user', {
        p_email: visitorInfo.email,
        p_first_name: visitorInfo.first_name,
        p_last_name: visitorInfo.last_name,
        p_document_type: 'dni',
        p_document_number: visitorInfo.document_number,
        p_phone: visitorInfo.phone,
        p_user_type: 'visitor',
        p_apartment: [],
        p_building: []
      });

      const newUser = await secureApi.get('users', {
        filter: { id: userId },
        single: true
      });

      return newUser;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  },

  getWorkerBuildings: async (userId) => {
    return secureApi.get('worker_buildings', {
      select: 'building_id',
      filter: { worker_id: userId, is_active: true }
    });
  },

  fetchApartmentsForBuilding: async (buildingId) => {
    return secureApi.get('apartments', {
      select: 'id, apartment_number',
      filter: { building_id: buildingId }
    });
  },

  registerVisit: async (visitorId, apartmentId, visitorEmail, visitorDocumentNumber) => {
    const currentTime = new Date().toISOString();

    const visitData = {
      visitor_id: visitorId,
      apartment_id: apartmentId,
      status: 'checked_in',
      created_at: currentTime,
      check_in_time: currentTime,
      visitor_email: visitorEmail,
      visitor_document_number: visitorDocumentNumber,
      purpose: 'walk-in'
    };

    try {
      const data = await secureApi.post('visits', visitData);
      return data;
    } catch (error) {
      console.error('Error registering visit:', error);
      throw error;
    }
  }
};