// src/supabase/secureApi.js

import { supabase } from './client';
import { authTokenService } from '../features/auth/services/authTokenService';

export const secureApi = {
  async ensureAuth() {
    if (!authTokenService.isAuthenticated()) {
      throw new Error('No active session');
    }
    await authTokenService.init();
  },

  handleError(error) {
    if (error.status === 401 || error.message?.includes('JWT')) {
      authTokenService.clearSession();
    }
    console.error('API error:', error);
  },

  async get(table, options = {}) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      
      let query = supabase
        .from(table)
        .select(options.select || '*');

      if (options.filter) {
        Object.entries(options.filter).forEach(([key, value]) => {
          const [field, operator] = key.split('.'); // Split 'issue_date.gte' into ['issue_date', 'gte']
          if (operator) {
            switch (operator) {
              case 'gte':
                query = query.gte(field, value);
                break;
              case 'lte':
                query = query.lte(field, value);
                break;
              case 'gt':
                query = query.gt(field, value);
                break;
              case 'lt':
                query = query.lt(field, value);
                break;
              case 'neq':
                query = query.neq(field, value);
                break;
              case 'in':
                if (Array.isArray(value)) {
                  query = query.in(field, value);
                } else {
                  console.warn(`Expected array for 'in' filter on field '${field}', received:`, value);
                }
                break;
              // Add more operators as needed
              default:
                console.warn(`Unsupported operator '${operator}' for field '${field}'. Defaulting to equality.`);
                query = query.eq(key, value);
            }
          } else {
            // No operator specified, default to equality
            query = query.eq(key, value);
          }
        });
      }

      if (options.single) {
        query = query.single();
      }

      if (options.order) {
        Object.entries(options.order).forEach(([key, direction]) => {
          query = query.order(key, { ascending: direction === 'asc' });
        });
      }

      // Logging for debugging
      // console.log(`Fetching from table: ${table}`);
      // console.log('Select:', options.select || '*');
      // console.log('Filters:', options.filter);
      // console.log('Order:', options.order);
      // console.log('Single:', options.single);

      const { data, error } = await query;
      
      if (error) {
        this.handleError(error);
        throw error;
      }
      
      return data;
    });
  },

  async post(table, data) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      const { data: result, error } = await supabase
        .from(table)
        .insert(data)
        .select()
        .single();

      if (error) throw error;
      return result;
    });
  },

  async update(table, id, data) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      const { data: result, error } = await supabase
        .from(table)
        .update(data)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return result;
    });
  },

  async delete(table, id) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      const { error } = await supabase
        .from(table)
        .delete()
        .eq('id', id);

      if (error) throw error;
    });
  },

  async rpc(functionName, params) {
    return authTokenService.retryOperation(async () => {
      await this.ensureAuth();
      console.log(`Calling secure RPC function: ${functionName} with params:`, params);
      
      const { data, error } = await supabase.rpc(functionName, params);

      if (error) {
        console.error(`RPC error in function ${functionName}:`, error);
        throw error;
      }

      console.log(`RPC function ${functionName} returned:`, data);
      return data;
    });
  }
};