// src/navigation/context/NavigationContext.js

import React, { createContext, useState, useContext } from 'react';

const NavigationContext = createContext();

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [params, setParams] = useState({});

  const value = {
    params,
    setParams,
  };

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};