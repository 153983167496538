// src/shared/layouts/AuthLayout.js

import React from "react";
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        py: 4,
      }}
    >
      <Box sx={{ maxWidth: 300, width: "100%", mb: 4 }}>
        <img src={"/images/domus.png"} alt="Domus" style={{ width: "100%" }} />
      </Box>
      <Box sx={{ maxWidth: 400, width: "100%" }}>
        <Outlet />
      </Box>
    </Container>
  );
};

export default AuthLayout;
