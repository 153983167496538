import React from 'react';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const StatsItem = ({ label, value }) => (
  <Box>
    <Typography
      sx={{
        fontSize: 14,
        fontWeight: 400,
        color: '#6B7280',
        marginBottom: '4px',
      }}
    >
      {label}
    </Typography>
    <Typography
      sx={{
        fontSize: 16,
        fontWeight: 600,
        color: '#111827',
      }}
    >
      {value}
    </Typography>
  </Box>
);

const MaintenanceFeeStats = ({ data, selectedDate }) => {
  const stats = data || {
    pagosRecibidos: 0,
    porValidar: 0,
    fechaVencimiento: '-',
    recaudado: 0,
    total: 0,
    progress: 0,
  };

  const progressWidth = stats.total > 0 ? (stats.recaudado / stats.total) * 100 : 0;

  // Format the period based on the selectedDate
  const periodoDate = new Date(`${selectedDate}-01T12:00:00`);
  const periodo = format(periodoDate, 'MMMM yyyy', { locale: es });

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: '16px',
        padding: '20px 24px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)',
        marginTop: '16px',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          alignItems: 'center',
          gap: 2,
          marginBottom: '20px',
        }}
      >
        <StatsItem
          label="Periodo"
          value={periodo.charAt(0).toUpperCase() + periodo.slice(1)}
        />
        <StatsItem label="Pagos Recibidos" value={stats.pagosRecibidos.toLocaleString('es-PE')} />
        <StatsItem label="Por validar" value={stats.porValidar.toLocaleString('es-PE')} />
        <StatsItem label="Fecha de vencimiento" value={stats.fechaVencimiento || '-'} />
        <StatsItem
          label="Recaudado"
          value={`S/ ${stats.recaudado.toLocaleString('es-PE')}/${stats.total.toLocaleString('es-PE')}`}
        />
      </Box>
      <Box
        sx={{
          height: '8px',
          backgroundColor: '#E5E7EB',
          borderRadius: '6px',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: `${progressWidth}%`,
            backgroundColor:
              progressWidth <= 33
                ? '#DC2626' // Red
                : progressWidth <= 66
                ? '#FBBF24' // Yellow
                : '#22C55E', // Green
            transition: 'width 0.3s ease-in-out, background-color 0.3s ease-in-out',
          }}
        />
      </Box>
    </Box>
  );
};

export default MaintenanceFeeStats;